import React from "react";
import deprontoImg from "./Images/depronto.png";
import deprontoMobImg from "./Images/deprontoMobileView.png";
import "./comingsoon.css";

const DeprontoBanner = () => {
  return (
    <section className="deprontoComingContainer">
      <img src={deprontoImg} alt="coming soon" className="coming-soon-banner" />
      <img
        src={deprontoMobImg}
        alt="coming soon"
        className="coming-soon-mob-banner"
      />
    </section>
  );
};

export default DeprontoBanner;
