import React from "react";
import "./SolutionCard.styles.css";


interface Title {
  titleText?: string;
  titleStyle?: React.CSSProperties;
}

interface Description {
  descriptionText?: string;
  descriptionStyle?: React.CSSProperties;
}

interface Button {
  buttonText?: string;
  buttonStyle?: React.CSSProperties;
  buttonAction?:string;
}

interface SolutionCardProps {
  data?: {
    title?: Title;
    description?: Description;
    button?: Button;
  };
  logoPath?: string;
  givenStyles?: React.CSSProperties;
  logoStyles?: React.CSSProperties;
}

const SolutionCard: React.FC<SolutionCardProps> = ({
  data,
  logoPath,
  givenStyles,
  logoStyles,
}) => {
  if (!data) return null; // Handle case where data is not provided

  const { title, description, button } = data;
  const { titleText, titleStyle } = title || {};
  const { descriptionText, descriptionStyle } = description || {};
  const { buttonText, buttonStyle ,buttonAction} = button || {};
  const handleButtonClick = () => {
    console.log(buttonAction);
    window.open(buttonAction, '_blank'); // Open link in new tab
};

  return (
    <section style={givenStyles} className="solution-card-container">
      <section className="Solution-TextContainer">
        <span className="Solution-Heading" style={titleStyle}>
          {titleText}
        </span>
        <span className="Solution-Description" style={descriptionStyle}>
          {descriptionText}{" "}
        </span>
        <button style={buttonStyle} className="Solution-Button" onClick={handleButtonClick}> 
          {buttonText}{" "}
        </button>
      </section>
      <section className="Solution-logo-container" style={logoStyles}>
        <img
          src={logoPath}
          alt=""
          width={"100%"}
          height={"100%"}
          style={{ borderRadius: "7px" }}
        />
      </section>
    </section>
  );
};

export default SolutionCard;
