import ContactForm from "./ContactForm/ContactForm";
import "./contact.styles.css";
import mapImg from "../Common/ContactUsSvg/map.svg";
import contactIcon from "../Common/ContactUsSvg/contactIcon.svg";
import { useState } from "react";
import FormSuccessComp from "./FormSuccess/FormSuccessComp";

const ContactUs = () => {
  const [isFormSuccessFull, setIsFormSuccessfull] = useState(false);
  return (
    <div className="contactus-page-colntainer">
      <div className="contactUsBanner">
        <div className="contactUsBanner-itemContainer">
          <div className="mapImg">
            <img src={mapImg} alt="" />
          </div>
          <div className="contactImg">
            <img src={contactIcon} alt="" className="contacticon-img" />
          </div>
          <div className="contactUs-Text">
            <div className="contactBannerTitle">Contact Us</div>
            <div className="contactBannerSubTitle">
              Are you looking to boost your team with world-class software
              development talent? Or perhaps you need a top GRC platform? If so,
              get in touch today.
            </div>
          </div>
        </div>
      </div>
      <section className="contactus-page-form-container">
        {isFormSuccessFull ? (
          <FormSuccessComp setIsFormSuccessfull={setIsFormSuccessfull} />
        ) : (
          <ContactForm setIsFormSuccessfull={setIsFormSuccessfull} />
        )}
      </section>
    </div>
  );
};
export default ContactUs;
