import BannerImage from "../Common/BannerImage/BannerImage";
import "./AboutUs.styles.css";
import { useEffect, useState } from "react";
import ExperienceImpactCard from "../Common/ExperienceImpactCards/ExperienceImpactCard";
import CarrouselBanner from "../Common/CarrouselBanner/CarrouselBanner";
import csrouselSvg from "../Common/AboutUsSvg/aboutUsSvg.svg";
import { useNavigate } from "react-router-dom";
import feature1 from "./threeFeatureImages/feature1.png.png";
import feature2 from "./threeFeatureImages/feature2.png.png";
import feature3 from "./threeFeatureImages/feature3.png.png";
import MobileViewThreeFeatureSection from "./MobileViewThreeFeatureSection";
import csrouselSvg360 from "../Common/AboutUsSvg/aboutUsSvg360.svg";
const AboutUs = () => {
  // const finalImagePath =
  //   window.innerWidth <= 720 ? csrouselSvg360 : csrouselSvg;
  const [finalImagePath, setFinalImagePath] = useState(
    window.innerWidth <= 720 ? csrouselSvg360 : csrouselSvg
  );
  useEffect(() => {
    const handleResize = () => {
      const newImagePath =
        window.innerWidth <= 720 ? csrouselSvg360 : csrouselSvg;
      setFinalImagePath(newImagePath);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const CarouselBanner = {
    title: "About Company ",
    subTitle:
      "Our expertise is two-fold: Design to Code works to provide the best software development experts to augment your development team, while EvoNext, our advanced RegTech platform, is designed to manage governance, risk, and compliance from every angle.",
  };
  const Bnner1Data = {
    Title: {
      TitleText: "Company Story",
      TitleStyles: {
        color: "#ffff",
        fontSize: "clamp(1.75rem, 0.9941rem + 1.1799vw, 2rem)",
        lineHeight: "clamp(1.75rem, 0.9941rem + 1.1799vw, 2rem)",
      },
    },
    SubTitle: {
      SubTitleText:
        "We are a team of passionate developers but are otherwise referred to as your Growth Partners. Welcome here! We advise you to take a tour, get to know about us and what we do for our clients. We won't overload you with a lot of information and prefer to speak with you across the table. While you are here, do check out our work. You will feel our obsession with Application Development and Programming.",
      SubTitleStyles: {
        color: "#ffff",
        fontSize: "clamp(0.75rem, 0.3721rem + 0.59vw, 0.875rem)",
        lineHeight: "clamp(1.125rem, 0.5581rem + 0.885vw, 1.3125rem)",
      },
    },
    Button: {
      ButtonText: "",
      ButtonStyles: {},
    },
  };

  const threeFeatureData1 = {
    imagepath: feature1,
    description: [
      "Everything we do is aimed at increasing customer satisfaction. After all, your success is our success, and we’d love to work with you for the long term! We’ll strive to exceed every requirement, becoming an integral part of your business set-up. Plus, we’ll respond promptly to enquiries, work diligently on all tasks, and put your needs at the top of our to-do list.",
    ],
  };
  const threeFeatureData2 = {
    imagepath: feature2,
    description: [
      "We offer the highest level of service. So, you might think that our prices are also the highest around. But you’d be wrong. Instead, we aim to offer a fair price for all, allowing you to build your business with the best people and tools without breaking the bank in the process. Get a quote today and find out how fairly priced we are!",
    ],
  };
  const threeFeatureData3 = {
    imagepath: feature3,
    description: [
      "We’re strict adherents to the idea of Kaizen – making small yet continuous improvements to boost overall productivity and effectiveness. And that’s what we’ll do when we work alongside you – ensure your business is improved every day. After all, just a 1% improvement is gigantic when achieved every day for a month.",
    ],
  };
  const tempFeature = {
    feature1: false,
    feature2: false,
    feature3: false,
  };
  const [featue, setFeature] = useState({ ...tempFeature, feature1: true });
  const featureSelect = (act: string) => {
    setFeature({ ...tempFeature, [act]: true });
  };
  const navigate = useNavigate();
  const navFunc = () => {
    navigate("/contact");
  };
  return (
    <section className="aboutUs-page-main-container">
      <section>
        <CarrouselBanner
          ButtonOnclick={navFunc}
          data={CarouselBanner}
          bgSvg={finalImagePath}
          textAllignMentRight={true}
        />
      </section>
      <section>
        <MobileViewThreeFeatureSection></MobileViewThreeFeatureSection>
      </section>
      <section
        // style={{ width: "960px", margin: "0 auto" }}
        className="threeFeature"
      >
        <header className="threeFeature-heading">
          Experience DePronto’s Impact{" "}
        </header>
        <div className="threeFeature-button-container">
          <button
            className={`threeFeature-button ${
              featue.feature1 ? "active-Button" : ""
            }`}
            style={{
              width: "162px",
              height: "28px",
            }}
            onClick={() => featureSelect("feature1")}
          >
            Customer Focused
          </button>
          <button
            className={`threeFeature-button ${
              featue.feature2 ? "active-Button" : ""
            }`}
            style={{ width: "196px", height: "28px" }}
            onClick={() => featureSelect("feature2")}
          >
            Great Value
          </button>
          <button
            className={`threeFeature-button ${
              featue.feature3 ? "active-Button" : ""
            }`}
            style={{ width: "138px", height: "28px" }}
            onClick={() => featureSelect("feature3")}
          >
            Kaizen Thinking
          </button>
        </div>
        <section>
          <ExperienceImpactCard
            data={
              featue.feature1
                ? threeFeatureData1
                : featue.feature2
                ? threeFeatureData2
                : threeFeatureData3
            }
          />
        </section>
      </section>
    </section>
  );
};
export default AboutUs;
