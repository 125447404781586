import { NavLink } from "react-router-dom";
import "./Header.styles.css";
import deprontoLogo from "../Common/HeaderIcons/Frame 1000004920.png";
import hamburger from "../Common/HeaderIcons/hamburger.svg";
import { useEffect, useRef, useState } from "react";
import HeaderMenu from "./HeaderMenu";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const closeDropdown = () => {
    setIsOpen(false);
  };

  return (
    <header className="header-container">
      <section className="header-content">
        <aside className="header-logo">
          <img src={deprontoLogo} alt="Depronto" className="deprontoLogo" />
        </aside>
        <nav className="header-links">
          <NavLink to={"/home"} className={"header-link"} style={{ width: 79 }}>
            Home
          </NavLink>
          <NavLink
            to={"/about"}
            className={"header-link"}
            style={{ width: 109 }}
          >
            About Us
          </NavLink>
          <NavLink
            to={"/careers"}
            className={"header-link"}
            style={{ width: 93 }}
          >
            Careers
          </NavLink>
          <NavLink
            to={"/contact"}
            className={"header-link"}
            style={{ width: 118 }}
          >
            Contact Us
          </NavLink>
        </nav>
        <aside className="hamburger-icon">
          <img src={hamburger} alt="menu" onClick={toggleDropdown} />

          {isOpen && <HeaderMenu closeDropdown={closeDropdown} />}
        </aside>
      </section>
    </header>
  );
};

export default Header;
