import React from "react";
import evonextImg from "./Images/evonext.png";
import evonextMobImg from "./Images/evonextMobileView.png";
import "./comingsoon.css";

const EvonextBanner = () => {
  return (
    <section className="evonextContainer">
      <img src={evonextImg} alt="coming soon" className="coming-soon-banner" />
      <img
        src={evonextMobImg}
        alt="coming soon"
        className="coming-soon-mob-banner"
      />
    </section>
  );
};

export default EvonextBanner;
