import { useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
interface HeaderMenuProps {
  closeDropdown: () => void;
}
const HeaderMenu: React.FC<HeaderMenuProps> = ({ closeDropdown }) => {
  const handleLinkClick = () => {
    closeDropdown();
  };
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        closeDropdown();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);
  return (
    <div ref={dropdownRef} className={"header-menu-link-container"}>
      <NavLink
        to={"/home"}
        className={"header-menu-link"}
        onClick={handleLinkClick}
        style={{ borderRadius: "4px 4px 0 0" }}
      >
        Home
      </NavLink>
      <NavLink
        to={"/about"}
        className={"header-menu-link"}
        onClick={handleLinkClick}
      >
        About Us
      </NavLink>
      <NavLink
        to={"/careers"}
        className={"header-menu-link"}
        onClick={handleLinkClick}
      >
        Careers
      </NavLink>
      <NavLink
        to={"/contact"}
        className={"header-menu-link"}
        onClick={handleLinkClick}
        style={{ borderRadius: " 0 0 4px 4px" }}
      >
        Contact Us
      </NavLink>
    </div>
  );
};

export default HeaderMenu;
