import React from "react";
import "./CarrouselBanner.css";
type CarrouselBannerProps = {
  data: {
    title: string;
    subTitle: string;
  };

  imgAllignment?: boolean;
  textAllignMentRight?: boolean;
  textCorousel?:number;
  bgSvg: string;
  ButtonOnclick: () => void; // Assuming ButtonOnclick is a function
};

const CarrouselBanner: React.FC<CarrouselBannerProps> = ({
  data,
  imgAllignment,
  bgSvg,
  ButtonOnclick,
  textAllignMentRight,
  textCorousel
}) => {
  const { title, subTitle } = data;
  var classname="CarrouselBanner-textContainer";
  var imageclassname="carousel-img-right-container";
  console.log(textCorousel);
  if(textCorousel===1){
     classname = "CarrouselBanner-textContainer1";
  }else if(textCorousel===2){
    classname = "CarrouselBanner-textContainer2";
    imageclassname="carousel-img-right-container2"
  }else if(textCorousel===3){
    classname = "CarrouselBanner-textContainer3";
  }
  console.log(imgAllignment);
 
  return (
    <>
      <div className="CarrouselBanner-Container">
        <div
          className={imgAllignment ? `${imageclassname}`: undefined}
        >
          <img src={bgSvg} alt="" />
        </div>
        <div className="carrouselBanner-overlap">
          <div
            className={`${classname} ${
              textAllignMentRight ? "carousel-text-right" : ""
            }`}
          >
            <div className="CarrouselBanner-Title">{title} </div>
            <div className="CarrouselBanner-SubTitle">{subTitle} </div>
            <button className="CarrouselBanner-Button" onClick={ButtonOnclick}>
              Get in Touch
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default CarrouselBanner;
