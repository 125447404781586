import SolutionCard from "../Common/SolutionCard/SolutionCard";
import "./Home.styles.css";
import Carousel from "../HeroSection/Carousel";
import WhyUs from "../WhyUs/WhyUs";
import HeaderMenu from "../Header/HeaderMenu";
import solutioncard1 from "./assets/SolutionLogos/evonextLogo.png";
import solutioncard2 from "./assets/SolutionLogos/logo2.png";
import solutioncard1360 from "./assets/SolutionLogos/evonext360Logo.png";
import solutioncard2360 from "./assets/SolutionLogos/logo360.png";
import { useEffect, useState } from "react";
const Home = () => {
  //const finalImagePath1 =  window.innerWidth<=720 ? solutioncard1360  : solutioncard1;
  // const finalImagePath2 =  window.innerWidth<=720 ? solutioncard2360  : solutioncard2;
  const [finalImagePath1, setFinalImagePath1] = useState(
    window.innerWidth <= 720 ? solutioncard1360 : solutioncard1
  );
  const [finalImagePath2, setFinalImagePath2] = useState(
    window.innerWidth <= 720 ? solutioncard2360 : solutioncard2
  );
  useEffect(() => {
    const handleResize = () => {
      const newImagePath1 =
        window.innerWidth <= 720 ? solutioncard1360 : solutioncard1;
      setFinalImagePath1(newImagePath1);
      const newImagePath2 =
        window.innerWidth <= 720 ? solutioncard2360 : solutioncard2;
      setFinalImagePath2(newImagePath2);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const solnCardData1 = {
    title: {
      titleText: "EvoNext: Step Up Your GRC Game  ",
      titleStyle: { color: "#ffff" },
    },
    description: {
      descriptionText:
        "Do you need to implement effective business continuity management? Or perhaps you need an effective, intelligent system for managing business change? Our proprietary GRC (Governance, Risk & Compliance) software, EvoNext, covers a broad range of uses and ensures intelligent business strategies. Use EvoNext to streamline how your company makes decisions and guarantee compliance with all relevant rules and regulations. ",
      descriptionStyle: { color: "#ffff" },
    },
    button: {
      buttonText: "Know More",
      buttonStyle: { color: "#ffff", backgroundColor: "#0031FF" },
      // buttonAction: "./#/evonext"
      buttonAction: "https://evonext.ai/#/home",
    },
  };
  const solnCardData2 = {
    title: {
      titleText: "Design to Code: Software Experts ",
      titleStyle: { color: "#ffff" },
    },
    description: {
      descriptionText:
        "Work with a range of software development experts – including front-end and back-end professionals proficient in various tech stacks, like HTML, React, Javascript, Java, SQL and more – to take your software from conception to completion. They’re on hand to assist both short-term and long-term with design, code, testing, and more, filling gaps in your team and saving you money in the process. ",
      descriptionStyle: { color: "#ffff" },
    },
    button: {
      buttonText: "Know More",
      buttonStyle: { color: "#ffff", backgroundColor: "#FF7A00" },
      buttonAction: "./#/designtocode",
    },
  };
  return (
    <div style={{ width: "100%", margin: "0 auto" }}>
      {/* <HeaderMenu /> */}
      <Carousel />
      {/* solutions part */}

      <section className="solutions-container">
        <header className="solutions-Heading">Solutions</header>
        <main className="solutions-cards">
          <SolutionCard
            data={solnCardData1}
            givenStyles={{ backgroundColor: "#000000" }}
            logoPath={finalImagePath1}
            logoStyles={{ bottom: "4px", right: "10px" }}
          />
          <SolutionCard
            data={solnCardData2}
            givenStyles={{ backgroundColor: "#000123" }}
            logoPath={finalImagePath2}
          />
        </main>
      </section>
      <section className="home-whyus-parent-container">
        <WhyUs />
      </section>
      {/* <CareerOpportunities/>  */}
    </div>
  );
};
export default Home;
