import React, { useEffect, useRef, useState } from "react";
import "./dropDown.css";

const DropDown = ({
  options,
  onOptionClick,
  onClose,
  isSearchEnable,
  containerClass = "",
  containerItemclass = "",
}: {
  options: any[];
  onOptionClick: (item: any) => void;
  onClose: () => void;
  isSearchEnable: boolean;
  containerClass?: string;
  containerItemclass?: string;
}) => {
  const [filterOption, SetFilterOptons] = useState([...options]);
  const [search, setSearch] = useState("");

  //   handling outside click
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  //   filtering the input
  useEffect(() => {
    const filteredOptions = options.filter((item) =>
      item.name.toLowerCase().startsWith(search.toLowerCase())
    );
    SetFilterOptons(filteredOptions);
  }, [search, options]);

  return (
    <div className={`dropdownContainer ${containerClass}`} ref={wrapperRef}>
      {isSearchEnable && (
        <div className="dropDownSearch">
          <label className="dropdownSearchLabel">
            <input
              type="text"
              placeholder="search"
              onChange={(e) => setSearch(e.target.value)}
            />
          </label>
        </div>
      )}
      <div className="dropDownOptionContainer">
        {filterOption.map((item, i) => (
          <span
            key={i}
            onClick={() => onOptionClick(item)}
            className={`dropDownOptions ${containerItemclass}`}
          >
            {item.name}{" "}
          </span>
        ))}
      </div>
    </div>
  );
};

export default DropDown;
