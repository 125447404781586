import "./CareerOpportunities.css";
import SlideCard from "../Common/careerOppCarrousel/CarrerOppCarrousel";
// import SlideCard from "../Common/SlideCard/SlideCard";
// import bannerImg from "./BannerImg/careerOpp.jpg";
import bannerImg from "./BannerImg/careerOpp3.jpg";

function CareerOpportunities() {
  return (
    <div className="container-fluid slidecard-main-container">
      <p className="careerOpprtunities-heading">Career Opportunities</p>
      <div className="careerOpprtunities-container">
        <img src={bannerImg} alt="" className="img-fluid carrerimg" />

        <div className="grid-item2">
          <SlideCard />
        </div>
      </div>
    </div>
  );
}

export default CareerOpportunities;
