import { useEffect, useState } from "react";
import "./BannerImage.styles.css";

type Title = {
  TitleText?: string;
  TitleStyles?: React.CSSProperties;
};

type SubTitle = {
  SubTitleText?: string;
  SubTitleStyles?: React.CSSProperties;
};

type Button = {
  ButtonText?: string;
  ButtonStyles?: React.CSSProperties;
};

type BannerData = {
  Title?: Title;
  SubTitle?: SubTitle;
  Button?: Button;
};
type Position = "top-left" | "top-right" | "bottom-left" | "bottom-right";

type BannerImageProps = {
  data?: BannerData;
  position?: Position;
  imagePath?: string;
  height?: number | string;
  smallImagePath: string;
  small360ImagePath: string;
  width?: number;
  TextContainerStyle?: React.CSSProperties;
  ImageFilter?: React.CSSProperties;
  onButtonClick?: () => any;
};

const BannerImage: React.FC<BannerImageProps> = ({
  data,
  position = "top-left",
  imagePath,
  height = 627,
  width = "100%",
  smallImagePath,
  small360ImagePath,
  TextContainerStyle,
  ImageFilter,
  onButtonClick,
}) => {
 // const finalImagePath = window.innerWidth <= 1024 ? window.innerWidth<=750 ? small360ImagePath : smallImagePath : imagePath;
  const [finalImagePath, setFinalImagePath] = useState(
    window.innerWidth <= 1024 ? window.innerWidth<=750 ? small360ImagePath : smallImagePath : imagePath
  );
  useEffect(() => {
    const handleResize = () => {
      const newImagePath =
      window.innerWidth <= 1024 ? window.innerWidth<=750 ? small360ImagePath : smallImagePath : imagePath
      setFinalImagePath(newImagePath);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const titleText = data?.Title?.TitleText || "";
  const titleStyles = data?.Title?.TitleStyles;
  const subTitleText = data?.SubTitle?.SubTitleText || "";
  const subTitleStyles = data?.SubTitle?.SubTitleStyles;
  const buttonText = data?.Button?.ButtonText || "";
  const buttonStyles = data?.Button?.ButtonStyles;

  return (
    <>
      <section
        // style={{ width: "100%", height: "100%" }}
        className="Banner-Image-Container"
      >
        <section className="Banner-Image-filter">
          <img
            src={finalImagePath}
            alt="banner"
            className="Banner-Image"
            height={height}
            style={ImageFilter}
            // width={width}
          />
        </section>
        <div
          style={{
            position: "absolute",
            maxWidth: 1302,
            width: "100%",
            height: "100%",
            top: 0,
            left: "50%",
            margin: "0 auto",
            transform: "translateX(-50%)",
          }}
        >
          <div>
            <section
              className={`Banner-Image-Text-Container ${position}`}
              style={TextContainerStyle}
            >
              {titleText.length > 0 && (
                <h1 style={titleStyles} className="BannerImg-title">
                  {" "}
                  {titleText}
                </h1>
              )}
              {subTitleText.length > 0 && (
                <h2 style={subTitleStyles} className="BannerImg-subTitle">
                  {" "}
                  {subTitleText}
                </h2>
              )}
              {buttonText.length > 0 && (
                <button
                  style={buttonStyles}
                  className="BannerImg-Button"
                  onClick={onButtonClick}
                >
                  {" "}
                  {buttonText}
                </button>
              )}
            </section>
          </div>
        </div>
      </section>
    </>
  );
};

export default BannerImage;
