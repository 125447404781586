import React from "react";
import "./flipcard.css";

interface FlipPropes {
  frontHeader: string;
  backContain: string;
}
function Flipcard({ frontHeader, backContain }: FlipPropes) {
  return (
    <div className="cardBox">
      <div className="cardDiv">
        <div className="front">
          <div className="front-contain">
            <h3>{frontHeader}</h3>
          </div>
        </div>
        <div className="back">
          <p className="backtext">{backContain}</p>
        </div>
      </div>
    </div>
  );
}

export default Flipcard;
