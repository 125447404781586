import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

const ScrollToTop = () => {
  const location = useLocation();
  const { page } = useParams();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" }); // Immediate scroll to the top
  }, [location]);

  return null; // Return null instead of an empty fragment
};

export default ScrollToTop;
