import { useEffect, useRef, useState } from "react";
import CareerOpportunities from "../CareerOpprtunities/CareerOpportunities";
import BannerImage from "../Common/BannerImage/BannerImage";
import "./careers.styles.css";
import WhyUsCareer from "./WhyChooseUsCareer/WhyChooseUsCarreer";
import careerBannerImg from "./assets/careerBanner.png";
import careerBanner1024Img from "./assets/careerbanner1024.png";
import careerBanner360Img from "./assets/careerbanner360.jpg";

const Careers = () => {
  const [addClass, setAddClass] = useState(false);
  const targetSectionRef = useRef<HTMLDivElement>(null);
  const scrollToSection = () => {
    if (targetSectionRef.current) {
      targetSectionRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    setAddClass(true);
    setTimeout(() => {
      setAddClass(false);
    }, 1100);
  };

  // useEffect(() => {
  //   const observer = new IntersectionObserver(
  //     ([entry]) => {
  //       setAddClass(entry.isIntersecting);
  //     },
  //     {
  //       root: null,
  //       rootMargin: "0px",
  //       threshold: 0.5,
  //     }
  //   );

  //   if (targetSectionRef.current) {
  //     observer.observe(targetSectionRef.current);
  //   }

  //   return () => {
  //     if (targetSectionRef.current) {
  //       observer.unobserve(targetSectionRef.current);
  //     }
  //   };
  // }, []);

  const BnnerData = {
    Title: {
      TitleText: "Careers at DePronto Infotech",
      TitleStyles: {
        color: "#FFFFFF",
        fontSize: "clamp(1.75rem, 0.9941rem + 1.1799vw, 2rem)",
        lineHeight: "clamp(1.75rem, 0.9941rem + 1.1799vw, 2rem)",
        marginBottom: 8 /*mehul*/,
      },
    },
    SubTitle: {
      SubTitleText:
        "Our team is the best around. Think you can make it even better? We’re always looking for the best talent – if that’s you, get in touch today. ",
      SubTitleStyles: {
        color: "#FFFFFF",
        fontSize: "clamp(0.75rem, -0.7618rem + 2.3599vw, 1.25rem)",
        lineHeight: "clamp(1.125rem, -1.1427rem + 3.5398vw, 1.875rem)",
        marginBottom: 24 /*mehul*/,
        fontWeight: 400,
      },
    },
    Button: {
      ButtonText: "Get in Touch",
      ButtonStyles: {},
    },
  };

  return (
    <div className="carrerdiv">
      {/* career journey */}
      <section>
        <BannerImage
          data={BnnerData}
          small360ImagePath={careerBanner360Img}
          smallImagePath={careerBanner1024Img}
          position="top-left"
          imagePath={careerBannerImg}
          TextContainerStyle={{ width: "57%" }}
          onButtonClick={scrollToSection}
        />
      </section>
      <section className="whyChooseUs">
        <WhyUsCareer />
      </section>
      <section className="careeropportunitiesdiv">
        <CareerOpportunities />
      </section>
      <div ref={targetSectionRef}></div>
      <section className={`careerJourney-container ${addClass && "animation"}`}>
        <section className="careerJourney-text">
          <section className="careerJourney-header">
            Start your career journey at DePronto
          </section>
          <section className="careerJourney-description">
            At DePronto, we’re always looking to augment our pool of
            professionals by working with the best in their fields. We'd love to
            hear from you if you’ve got talent, ambition, and an unflinching
            drive to succeed. So, what are you waiting for? Send us an email and
            start your journey with DePronto today! 
          </section>
        </section>
        <footer className="careerJourney-footer">
          If you think you’re the right fit for DePronto, email us your resume
          today at <u>hr@depronto.co.uk</u>.   {" "}
        </footer>
      </section>
    </div>
  );
};
export default Careers;
