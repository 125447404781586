import React, { useEffect, useState } from "react";
import "./Carousel.css";
import BannerImage from "../Common/BannerImage/BannerImage";
import { useNavigate } from "react-router-dom";
import CarrouselBanner from "../Common/CarrouselBanner/CarrouselBanner";
import csrouselSvg1 from "../Common/caouselSvgs/carouselSvg1.svg";
import csrouselSvg2 from "../Common/caouselSvgs/carouselSvg2.svg";
import csrouselSvg3 from "../Common/caouselSvgs/carouselSvg3.svg";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

function Carousel() {
  const navigate = useNavigate();

  const [slideIndex, setSlideIndex] = useState(0);

  const navFunc = () => {
    navigate(`/contact`);
  };
  const CarouselBanner1 = {
    title: "Save Today. Build for Tomorrow ",
    subTitle:
      "Our talented pool of IT professionals enables quick scalability without the need to commit to long-term employment, providing enhanced business security. Additionally, our services allow customers to save money on software development. So, you save money and boost business viability, all while driving down costs. It’s a win-win.",
  };
  const CarouselBanner2 = {
    title: "Partners in Your Success ",
    subTitle:
      "We’ll work alongside you with the same goal in mind – to bring success to your business. Whether you’re looking for help with software development or GRC solutions, we’ve got the experience and knowledge needed to drive your business forward. Our reputation, built over nearly two decades, speaks for itself, proving we offer the expert solutions needed to take your business to the next level. ",
  };
  const CarouselBanner3 = {
    title: "Experts in Governance, Risk & Compliance Solutions   ",
    subTitle:
      "Providing the solutions you need for governance, risk, and compliance via EvoNext. This proprietary Governance, Risk & Compliance program is used for change management, business continuity management, outsourcing risk management, AI governance and risk management, and process risk and control management. It’s everything you need for Governance, Risk & Compliance, all rolled into one convenient package. ",
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSlideIndex((prevIndex) => (prevIndex + 1) % 3);
    }, 7000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const carousel = document.getElementById("carouselExampleIndicators");
    const slideToIndex = slideIndex.toString();
    // @ts-ignore
    carousel?.querySelector(`[data-bs-slide-to="${slideToIndex}"]`)?.click();
  }, [slideIndex]);

  return (
    <>
      <div className="carousel-container ">
        <div
          id="carouselExampleIndicators"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-indicators" style={{ bottom: "-38px" }}>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              style={{ backgroundColor: "#DDE0E4" }}
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              style={{ backgroundColor: "#DDE0E4" }}
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              style={{ backgroundColor: "#DDE0E4" }}
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <CarrouselBanner
                ButtonOnclick={navFunc}
                data={CarouselBanner1}
                bgSvg={csrouselSvg1}
                textCorousel={1}
              />
            </div>
            <div className="carousel-item">
              <CarrouselBanner
                ButtonOnclick={navFunc}
                data={CarouselBanner2}
                bgSvg={csrouselSvg2}
                imgAllignment={true}
                textCorousel={2}
              />
            </div>
            <div className="carousel-item">
              <CarrouselBanner
                ButtonOnclick={navFunc}
                imgAllignment={true}
                data={CarouselBanner3}
                bgSvg={csrouselSvg3}
                textCorousel={3}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Carousel;
