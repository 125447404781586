import "./ExperienceImpactCard.styles.css";

interface ExperienceImpactCardProps {
  data: {
    imagepath: string;
    description: string[];
  };
}

const ExperienceImpactCard: React.FC<ExperienceImpactCardProps> = ({
  data,
}) => {
  const { imagepath, description } = data;
  return (
    <section style={{ width: "100%" }}>
      <section
        // style={{ width: "960px", margin: "auto" }}
        className="exp-card-container"
      >
        <section className="exp-card-image-container">
          <img src={imagepath} alt="exp" className="exp-card-img" />
        </section>
        <section className="exp-card-text-container">
          <div>
            {description.map((item, i) => (
              <p key={i} className="exp-card-text">
                {item}{" "}
              </p>
            ))}
          </div>
        </section>
      </section>
    </section>
  );
};

export default ExperienceImpactCard;
