// import React, { useEffect, useState } from "react";

// const CountryList = () => {
//   const [countries, setCountries] = useState<
//     { name: string; callingCode: string }[]
//   >([]);

//   useEffect(() => {
//     fetch("https://restcountries.com/v2/all")
//       .then((response) => response.json())
//       .then((data) => {
//         const countriesWithCallingCodes = data.map((country: any) => ({
//           name: country.name,
//           callingCode: country.callingCodes[0], // Assuming one calling code per country
//         }));
//         setCountries(countriesWithCallingCodes);
//         console.log(countriesWithCallingCodes);
//       })
//       .catch((error) => console.error("Error fetching data:", error));
//   }, []);
//   return countries;
// };

const CountryList = [
  { name: "Afghanistan", callingCode: "93" },
  { name: "Åland Islands", callingCode: "358" },
  { name: "Albania", callingCode: "355" },
  { name: "Algeria", callingCode: "213" },
  { name: "American Samoa", callingCode: "1" },
  { name: "Andorra", callingCode: "376" },
  { name: "Angola", callingCode: "244" },
  { name: "Anguilla", callingCode: "1" },
  { name: "Antarctica", callingCode: "672" },
  { name: "Antigua and Barbuda", callingCode: "1" },
  { name: "Argentina", callingCode: "54" },
  { name: "Armenia", callingCode: "374" },
  { name: "Aruba", callingCode: "297" },
  { name: "Australia", callingCode: "61" },
  { name: "Austria", callingCode: "43" },
  { name: "Azerbaijan", callingCode: "994" },
  { name: "Bahamas", callingCode: "1" },
  { name: "Bahrain", callingCode: "973" },
  { name: "Bangladesh", callingCode: "880" },
  { name: "Barbados", callingCode: "1" },
  { name: "Belarus", callingCode: "375" },
  { name: "Belgium", callingCode: "32" },
  { name: "Belize", callingCode: "501" },
  { name: "Benin", callingCode: "229" },
  { name: "Bermuda", callingCode: "1" },
  { name: "Bhutan", callingCode: "975" },
  { name: "Bolivia (Plurinational State of)", callingCode: "591" },
  { name: "Bonaire, Sint Eustatius and Saba", callingCode: "599" },
  { name: "Bosnia and Herzegovina", callingCode: "387" },
  { name: "Botswana", callingCode: "267" },
  { name: "Bouvet Island", callingCode: "47" },
  { name: "Brazil", callingCode: "55" },
  { name: "British Indian Ocean Territory", callingCode: "246" },
  { name: "United States Minor Outlying Islands", callingCode: "246" },
  { name: "Virgin Islands (British)", callingCode: "1" },
  { name: "Virgin Islands (U.S.)", callingCode: "1 340" },
  { name: "Brunei Darussalam", callingCode: "673" },
  { name: "Bulgaria", callingCode: "359" },
  { name: "Burkina Faso", callingCode: "226" },
  { name: "Burundi", callingCode: "257" },
  { name: "Cambodia", callingCode: "855" },
  { name: "Cameroon", callingCode: "237" },
  { name: "Canada", callingCode: "1" },
  { name: "Cabo Verde", callingCode: "238" },
  { name: "Cayman Islands", callingCode: "1" },
  { name: "Central African Republic", callingCode: "236" },
  { name: "Chad", callingCode: "235" },
  { name: "Chile", callingCode: "56" },
  { name: "China", callingCode: "86" },
  { name: "Christmas Island", callingCode: "61" },
  { name: "Cocos (Keeling) Islands", callingCode: "61" },
  { name: "Colombia", callingCode: "57" },
  { name: "Comoros", callingCode: "269" },
  { name: "Congo", callingCode: "242" },
  { name: "Congo (Democratic Republic of the)", callingCode: "243" },
  { name: "Cook Islands", callingCode: "682" },
  { name: "Costa Rica", callingCode: "506" },
  { name: "Croatia", callingCode: "385" },
  { name: "Cuba", callingCode: "53" },
  { name: "Curaçao", callingCode: "599" },
  { name: "Cyprus", callingCode: "357" },
  { name: "Czech Republic", callingCode: "420" },
  { name: "Denmark", callingCode: "45" },
  { name: "Djibouti", callingCode: "253" },
  { name: "Dominica", callingCode: "1" },
  { name: "Dominican Republic", callingCode: "1" },
  { name: "Ecuador", callingCode: "593" },
  { name: "Egypt", callingCode: "20" },
  { name: "El Salvador", callingCode: "503" },
  { name: "Equatorial Guinea", callingCode: "240" },
  { name: "Eritrea", callingCode: "291" },
  { name: "Estonia", callingCode: "372" },
  { name: "Ethiopia", callingCode: "251" },
  { name: "Falkland Islands (Malvinas)", callingCode: "500" },
  { name: "Faroe Islands", callingCode: "298" },
  { name: "Fiji", callingCode: "679" },
  { name: "Finland", callingCode: "358" },
  { name: "France", callingCode: "33" },
  { name: "French Guiana", callingCode: "594" },
  { name: "French Polynesia", callingCode: "689" },
  { name: "French Southern Territories", callingCode: "262" },
  { name: "Gabon", callingCode: "241" },
  { name: "Gambia", callingCode: "220" },
  { name: "Georgia", callingCode: "995" },
  { name: "Germany", callingCode: "49" },
  { name: "Ghana", callingCode: "233" },
  { name: "Gibraltar", callingCode: "350" },
  { name: "Greece", callingCode: "30" },
  { name: "Greenland", callingCode: "299" },
  { name: "Grenada", callingCode: "1" },
  { name: "Guadeloupe", callingCode: "590" },
  { name: "Guam", callingCode: "1" },
  { name: "Guatemala", callingCode: "502" },
  { name: "Guernsey", callingCode: "44" },
  { name: "Guinea", callingCode: "224" },
  { name: "Guinea-Bissau", callingCode: "245" },
  { name: "Guyana", callingCode: "592" },
  { name: "Haiti", callingCode: "509" },
  { name: "Heard Island and McDonald Islands", callingCode: "672" },
  { name: "Vatican City", callingCode: "379" },
  { name: "Honduras", callingCode: "504" },
  { name: "Hungary", callingCode: "36" },
  { name: "Hong Kong", callingCode: "852" },
  { name: "Iceland", callingCode: "354" },
  { name: "India", callingCode: "91" },
  { name: "Indonesia", callingCode: "62" },
  { name: "Ivory Coast", callingCode: "225" },
  { name: "Iran (Islamic Republic of)", callingCode: "98" },
  { name: "Iraq", callingCode: "964" },
  { name: "Ireland", callingCode: "353" },
  { name: "Isle of Man", callingCode: "44" },
  { name: "Israel", callingCode: "972" },
  { name: "Italy", callingCode: "39" },
  { name: "Jamaica", callingCode: "1" },
  { name: "Japan", callingCode: "81" },
  { name: "Jersey", callingCode: "44" },
  { name: "Jordan", callingCode: "962" },
  { name: "Kazakhstan", callingCode: "76" },
  { name: "Kenya", callingCode: "254" },
  { name: "Kiribati", callingCode: "686" },
  { name: "Kuwait", callingCode: "965" },
  { name: "Kyrgyzstan", callingCode: "996" },
  { name: "Lao People's Democratic Republic", callingCode: "856" },
  { name: "Latvia", callingCode: "371" },
  { name: "Lebanon", callingCode: "961" },
  { name: "Lesotho", callingCode: "266" },
  { name: "Liberia", callingCode: "231" },
  { name: "Libya", callingCode: "218" },
  { name: "Liechtenstein", callingCode: "423" },
  { name: "Lithuania", callingCode: "370" },
  { name: "Luxembourg", callingCode: "352" },
  { name: "Macao", callingCode: "853" },
  { name: "North Macedonia", callingCode: "389" },
  { name: "Madagascar", callingCode: "261" },
  { name: "Malawi", callingCode: "265" },
  { name: "Malaysia", callingCode: "60" },
  { name: "Maldives", callingCode: "960" },
  { name: "Mali", callingCode: "223" },
  { name: "Malta", callingCode: "356" },
  { name: "Marshall Islands", callingCode: "692" },
  { name: "Martinique", callingCode: "596" },
  { name: "Mauritania", callingCode: "222" },
  { name: "Mauritius", callingCode: "230" },
  { name: "Mayotte", callingCode: "262" },
  { name: "Mexico", callingCode: "52" },
  { name: "Micronesia (Federated States of)", callingCode: "691" },
  { name: "Moldova (Republic of)", callingCode: "373" },
  { name: "Monaco", callingCode: "377" },
  { name: "Mongolia", callingCode: "976" },
  { name: "Montenegro", callingCode: "382" },
  { name: "Montserrat", callingCode: "1" },
  { name: "Morocco", callingCode: "212" },
  { name: "Mozambique", callingCode: "258" },
  { name: "Myanmar", callingCode: "95" },
  { name: "Namibia", callingCode: "264" },
  { name: "Nauru", callingCode: "674" },
  { name: "Nepal", callingCode: "977" },
  { name: "Netherlands", callingCode: "31" },
  { name: "New Caledonia", callingCode: "687" },
  { name: "New Zealand", callingCode: "64" },
  { name: "Nicaragua", callingCode: "505" },
  { name: "Niger", callingCode: "227" },
  { name: "Nigeria", callingCode: "234" },
  { name: "Niue", callingCode: "683" },
  { name: "Norfolk Island", callingCode: "672" },
  { name: "Korea (Democratic People's Republic of)", callingCode: "850" },
  { name: "Northern Mariana Islands", callingCode: "1" },
  { name: "Norway", callingCode: "47" },
  { name: "Oman", callingCode: "968" },
  { name: "Pakistan", callingCode: "92" },
  { name: "Palau", callingCode: "680" },
  { name: "Palestine, State of", callingCode: "970" },
  { name: "Panama", callingCode: "507" },
  { name: "Papua New Guinea", callingCode: "675" },
  { name: "Paraguay", callingCode: "595" },
  { name: "Peru", callingCode: "51" },
  { name: "Philippines", callingCode: "63" },
  { name: "Pitcairn", callingCode: "64" },
  { name: "Poland", callingCode: "48" },
  { name: "Portugal", callingCode: "351" },
  { name: "Puerto Rico", callingCode: "1" },
  { name: "Qatar", callingCode: "974" },
  { name: "Republic of Kosovo", callingCode: "383" },
  { name: "Réunion", callingCode: "262" },
  { name: "Romania", callingCode: "40" },
  { name: "Russian Federation", callingCode: "7" },
  { name: "Rwanda", callingCode: "250" },
  { name: "Saint Barthélemy", callingCode: "590" },
  { name: "Saint Helena, Ascension and Tristan da Cunha", callingCode: "290" },
  { name: "Saint Kitts and Nevis", callingCode: "1" },
  { name: "Saint Lucia", callingCode: "1" },
  { name: "Saint Martin (French part)", callingCode: "590" },
  { name: "Saint Pierre and Miquelon", callingCode: "508" },
  { name: "Saint Vincent and the Grenadines", callingCode: "1" },
  { name: "Samoa", callingCode: "685" },
  { name: "San Marino", callingCode: "378" },
  { name: "Sao Tome and Principe", callingCode: "239" },
  { name: "Saudi Arabia", callingCode: "966" },
  { name: "Senegal", callingCode: "221" },
  { name: "Serbia", callingCode: "381" },
  { name: "Seychelles", callingCode: "248" },
  { name: "Sierra Leone", callingCode: "232" },
  { name: "Singapore", callingCode: "65" },
  { name: "Sint Maarten (Dutch part)", callingCode: "1" },
  { name: "Slovakia", callingCode: "421" },
  { name: "Slovenia", callingCode: "386" },
  { name: "Solomon Islands", callingCode: "677" },
  { name: "Somalia", callingCode: "252" },
  { name: "South Africa", callingCode: "27" },
  { name: "South Georgia and the South Sandwich Islands", callingCode: "500" },
  { name: "Korea (Republic of)", callingCode: "82" },
  { name: "Spain", callingCode: "34" },
  { name: "Sri Lanka", callingCode: "94" },
  { name: "Sudan", callingCode: "249" },
  { name: "South Sudan", callingCode: "211" },
  { name: "Suriname", callingCode: "597" },
  { name: "Svalbard and Jan Mayen", callingCode: "47" },
  { name: "Swaziland", callingCode: "268" },
  { name: "Sweden", callingCode: "46" },
  { name: "Switzerland", callingCode: "41" },
  { name: "Syrian Arab Republic", callingCode: "963" },
  { name: "Taiwan", callingCode: "886" },
  { name: "Tajikistan", callingCode: "992" },
  { name: "Tanzania, United Republic of", callingCode: "255" },
  { name: "Thailand", callingCode: "66" },
  { name: "Timor-Leste", callingCode: "670" },
  { name: "Togo", callingCode: "228" },
  { name: "Tokelau", callingCode: "690" },
  { name: "Tonga", callingCode: "676" },
  { name: "Trinidad and Tobago", callingCode: "1" },
  { name: "Tunisia", callingCode: "216" },
  { name: "Turkey", callingCode: "90" },
  { name: "Turkmenistan", callingCode: "993" },
  { name: "Turks and Caicos Islands", callingCode: "1" },
  { name: "Tuvalu", callingCode: "688" },
  { name: "Uganda", callingCode: "256" },
  { name: "Ukraine", callingCode: "380" },
  { name: "United Arab Emirates", callingCode: "971" },
  {
    name: "United Kingdom of Great Britain and Northern Ireland",
    callingCode: "44",
  },
  { name: "United States of America", callingCode: "1" },
  { name: "Uruguay", callingCode: "598" },
  { name: "Uzbekistan", callingCode: "998" },
  { name: "Vanuatu", callingCode: "678" },
  { name: "Venezuela (Bolivarian Republic of)", callingCode: "58" },
  { name: "Vietnam", callingCode: "84" },
  { name: "Wallis and Futuna", callingCode: "681" },
  { name: "Western Sahara", callingCode: "212" },
  { name: "Yemen", callingCode: "967" },
  { name: "Zambia", callingCode: "260" },
  { name: "Zimbabwe", callingCode: "263" },
];
export default CountryList;
