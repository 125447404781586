import { Link, useNavigate } from "react-router-dom";
import "./Footer.css";
import linkedinIcon from "../Common/icon/linkedinIcon.svg";
import whatsappIcon from "../Common/icon/whatsapp.png";

const Footer = () => {
  const navigate = useNavigate();
  const onClickNavigator = (act: string) => {
    if (act === "home") {
      navigate(`/home`, { replace: true });
    } else if (act === "aboutUs") {
      navigate(`/about`, { replace: true });
    } else if (act === "careers") {
      navigate(`/careers`);
    } else if (act === "contactUs") {
      navigate(`/contact`);
    }
  };

  const newScreen = () => {
    const newTab = window.open("/new", "_blank");
    // @ts-ignore
    newTab.focus();
  };
  return (
    <div style={{ backgroundColor: "#111C2F" }}>
      <div className="footer-main-container">
        <div className="container-fluid ">
          <div className="row">
            <div className="f-col-1">
              <div className="footer-heading ">Quick Links</div>
              <div className="footer-list-container-l">
                <div>
                  <ul className="footer-list-ul left-first">
                    <li className="footer-list-li-head">Sitemap</li>
                    <li
                      className="footer-list-li"
                      onClick={() => {
                        onClickNavigator("home");
                      }}
                    >
                      Home
                    </li>
                    <li
                      className="footer-list-li"
                      onClick={() => {
                        onClickNavigator("aboutUs");
                      }}
                    >
                      About Us
                    </li>
                    <li
                      className="footer-list-li"
                      onClick={() => {
                        onClickNavigator("careers");
                      }}
                    >
                      Careers
                    </li>

                    <li
                      className="footer-list-li"
                      onClick={() => {
                        onClickNavigator("contactUs");
                      }}
                    >
                      Contact Us
                    </li>
                  </ul>
                </div>
                <div>
                  <ul className="footer-list-ul left-second">
                    <li className="footer-list-li-head">Solutions</li>
                    <li className="footer-list-li">
                      <a href="./#/designtocode" target="_blank">
                        Design to Code
                      </a>
                    </li>
                    <li className="footer-list-li">
                      <a href="./#/evonext" target="_blank">
                        EvoNext
                      </a>
                    </li>
                  </ul>
                </div>
                <div>
                  <ul className="footer-list-ul left-third">
                    <li className="footer-list-li-head">Support</li>

                    <li className="footer-list-li">
                      <a
                        href="https://www.gdprprivacynotice.com/live.php?token=MHpZ9vGVTXG3XyQwa366HuABZevqzcKE"
                        target="_blank"
                      >
                        Privacy Policy
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="desktop-connect">
                  <ul className="footer-list-ul left-fourth">
                    <li className="footer-list-li-head">Connect</li>
                    <li className="footer-list-li">
                      {/* <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        fill="currentColor"
                        className="bi bi-linkedin"
                        viewBox="0 0 13 16"
                      >
                        <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z" />
                      </svg> */}
                      <a
                        href="https://www.linkedin.com/company/depronto/mycompany/"
                        target="_blank"
                      >
                        <img src={linkedinIcon} alt="" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="mobileViewConnect">
                <span className="">Connect</span>
                <a
                  href="https://www.linkedin.com/company/depronto/mycompany/"
                  target="_blank"
                >
                  <img src={linkedinIcon} alt="" />
                </a>
              </div>
            </div>
            <div className="f-col-2">
              <div className="footer-heading">Contact Us</div>
              <div className="footer-list-container-r container2">
                <div>
                  <ul className="footer-list-ul">
                    <li className="footer-list-li2">
                      <div className="d-flex">
                        <div className="footer-li-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            className="bi bi-geo-alt-fill footer-col-2-icons"
                            viewBox="0 0 20 20"
                          >
                            <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
                          </svg>
                        </div>
                        <div className="address-container">
                          <span className="address-Heading">EvoNext Ltd.</span>
                          <span>Harrow, London</span>
                          <span>United Kingdom</span>
                        </div>
                      </div>
                    </li>
                    <li className="footer-list-li2">
                      <div className="d-flex">
                        <div className="footer-li-icon">
                          {/* <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            className="bi bi-telephone-fill footer-col-2-icons"
                            viewBox="0 0 20 20"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"
                            />
                          </svg> */}
                          <img
                            src={whatsappIcon}
                            alt=""
                            height={14}
                            width={14}
                            style={{ marginRight: 2 }}
                          />
                        </div>
                        <div>+44 7732442498</div>
                      </div>
                    </li>
                    <li className="footer-list-li2 footer-list-li2-last">
                      <div className="d-flex">
                        <div className="footer-li-icon">
                          <svg
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                            className="footer-col-2-icons"
                          >
                            <path
                              d="M3.23078 14.625C2.85193 14.625 2.53125 14.4937 2.26875 14.2312C2.00625 13.9687 1.875 13.648 1.875 13.2692V4.73078C1.875 4.35193 2.00625 4.03125 2.26875 3.76875C2.53125 3.50625 2.85193 3.375 3.23078 3.375H14.7692C15.148 3.375 15.4687 3.50625 15.7312 3.76875C15.9937 4.03125 16.125 4.35193 16.125 4.73078V13.2692C16.125 13.648 15.9937 13.9687 15.7312 14.2312C15.4687 14.4937 15.148 14.625 14.7692 14.625H3.23078ZM8.99998 9.41822L15 5.58169L14.8846 4.49998L8.99998 8.24998L3.11537 4.49998L2.99998 5.58169L8.99998 9.41822Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                        <div>sales@depronto.co.uk</div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div>
                  <ul className="footer-list-ul">
                    <li className="footer-list-li2">
                      <div className="d-flex">
                        <div className="footer-li-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            className="bi bi-geo-alt-fill footer-col-2-icons"
                            viewBox="0 0 20 20"
                          >
                            <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
                          </svg>
                        </div>
                        <div className="address-container">
                          <span className="address-Heading ">
                            {" "}
                            DePronto Infotech
                          </span>
                          <span>Surat | Mumbai</span>
                          <span>India</span>
                        </div>
                      </div>
                    </li>
                    <li className="footer-list-li2">
                      <div className="d-flex">
                        <div className="footer-li-icon">
                          {" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            className="bi bi-telephone-fill footer-col-2-icons"
                            viewBox="0 0 20 20"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"
                            />
                          </svg>
                          {/* <img
                            src={whatsappIcon}
                            alt=""
                            height={18}
                            width={18}
                          /> */}
                        </div>
                        <div>+91 9920800008</div>
                      </div>
                    </li>
                    <li className="footer-list-li2">
                      <div className="d-flex">
                        {/* <div className="footer-li-icon"> */}{" "}
                        <div className="footer-li-icon">
                          <svg
                            className="footer-col-2-icons"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M3.23078 14.625C2.85193 14.625 2.53125 14.4937 2.26875 14.2312C2.00625 13.9687 1.875 13.648 1.875 13.2692V4.73078C1.875 4.35193 2.00625 4.03125 2.26875 3.76875C2.53125 3.50625 2.85193 3.375 3.23078 3.375H14.7692C15.148 3.375 15.4687 3.50625 15.7312 3.76875C15.9937 4.03125 16.125 4.35193 16.125 4.73078V13.2692C16.125 13.648 15.9937 13.9687 15.7312 14.2312C15.4687 14.4937 15.148 14.625 14.7692 14.625H3.23078ZM8.99998 9.41822L15 5.58169L14.8846 4.49998L8.99998 8.24998L3.11537 4.49998L2.99998 5.58169L8.99998 9.41822Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                        {/* </div> */}
                        <div>gaurav.kabra@deprontoinfotech.com</div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-tag">
          DePronto Infotech. All rights reserved.
        </div>
      </div>
    </div>
  );
};
export default Footer;
