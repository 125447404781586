import { Outlet, useLocation } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

const MainLayout = () => {
  const location = useLocation();
  const { pathname } = location;
  const noLayOutPaths = ["/evonext", "/designtocode"];
  console.log(pathname, "path");
  return (
    <>
      {!noLayOutPaths.includes(pathname) && <Header />}
      {!noLayOutPaths.includes(pathname) && (
        <div className="header-shadow"></div>
      )}

      <main
        style={{
          width: "100%",
          margin: "0 auto",
          overflow: "hidden",
        }}
      >
        <Outlet />
      </main>
      {!noLayOutPaths.includes(pathname) && <Footer />}
    </>
  );
};
export default MainLayout;
