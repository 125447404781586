import React from "react";
import Flipcard from "../../Common/FlipCard/Flipcard";

const cardData = [
  {
    frontHeaderData: "Diverse Roles ",
    backConrainData:
      "Our team members work with a range of companies, offering a diverse selection of roles, ensuring there’s always something to match your skills and interests. You’ll have the opportunity to develop your skills by working alongside various companies and their software development experts.",
  },
  {
    frontHeaderData: "Impressive Reputation ",
    backConrainData:
      "We’ve been around for nearly 20 years, building an incredible reputation within our industry. And we intend to keep this reputation, meaning we always keep the skills of our team members up to date, so they can seamlessly transfer into new and exciting roles. You won’t grow stagnant here – you’ll continuously evolve as a software development expert.",
  },
  {
    frontHeaderData: "Industry Leading Benefits ",
    backConrainData:
      "We know that getting the best experts means offering the best benefits. We offer industry-leading benefits to attract the best talent, including health insurance, remote working, wellness benefits, birthday & bereavement leave, etc. We believe the best workers should be treated to the best benefits – it’s that simple.",
  },
  {
    frontHeaderData: "Major Partners ",
    backConrainData:
      "We work with some of the biggest names in their respective industries, including Tier 1 Banks, Financial Services, Insurance companies, allowing you to get experience working on significant projects with big impacts. Working with major international clients will only enhance the CV of any software developer.",
  },
];

function WhyUsCareer() {
  return (
    <div style={{ marginBottom: "" }} className="whyus-parent-container">
      <h1 className="text-center p-0 m-0 choose-Us-heading">Why Choose Us</h1>
      <div className="why-us-container">
        {cardData.map((ele, index) => {
          return (
            <>
              <Flipcard
                key={index}
                frontHeader={ele.frontHeaderData}
                backContain={ele.backConrainData}
              />
            </>
          );
        })}
      </div>
    </div>
  );
}

export default WhyUsCareer;
