import React from "react";
import {
  HashRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import "@fontsource/metropolis/100.css";
import "@fontsource/metropolis/200.css";
import "@fontsource/metropolis/300.css";
import "@fontsource/metropolis/400.css";
import "@fontsource/metropolis/500.css";
import ScrollToTop from "./Common/TopScroller/ScrollToTop";
import MainLayout from "./Layouts/MainLayout";
import Home from "./Home/Home";
import AboutUs from "./AboutUs/AboutUs";
import Careers from "./Careers/Careers";
import ContactUs from "./ContactUs/ContactUs";
import EvonextBanner from "./Common/ComingSoonPages/EvonextBanner";
import DeprontoBanner from "./Common/ComingSoonPages/deprontoBanner";

function App() {
  return (
    <Router basename="/">
      <ScrollToTop />
      <div className="">
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route index element={<Navigate to="home" replace />} />
            <Route index path="home" element={<Home />} />
            <Route path="about" element={<AboutUs />} />
            <Route path="careers" element={<Careers />} />
            <Route path="contact" element={<ContactUs />} />
            <Route path="designtocode" element={<DeprontoBanner />} />
            <Route path="evonext" element={<EvonextBanner />} />
            <Route path="*" element={<Navigate to="home" replace />} />
          </Route>
          {/* <Route path="test" element={<Test />} /> */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
