import { useState } from "react";
import feature1 from "./threeFeatureImages/feature1.png.png";
import feature2 from "./threeFeatureImages/feature2.png.png";
import feature3 from "./threeFeatureImages/feature3.png.png";
import nextIcon from "./threeFeatureImages/nextIcon.svg";
import prevIcon from "./threeFeatureImages/prevIcon.svg";
import "./AboutUs.styles.css";

const threeFeatureData1 = {
  imagepath: feature1,
  heading: " Customer Focused",
  description: [
    "Everything we do is aimed at increasing customer satisfaction. After all, your success is our success, and we’d love to work with you for the long term! We’ll strive to exceed every requirement, becoming an integral part of your business set-up. Plus, we’ll respond promptly to enquiries, work diligently on all tasks, and put your needs at the top of our to-do list.",
  ],
};
const threeFeatureData2 = {
  imagepath: feature2,
  heading: " Great Value",
  description: [
    "We offer the highest level of service. So, you might think that our prices are also the highest around. But you’d be wrong. Instead, we aim to offer a fair price for all, allowing you to build your business with the best people and tools without breaking the bank in the process. Get a quote today and find out how fairly priced we are!",
  ],
};
const threeFeatureData3 = {
  imagepath: feature3,
  heading: "Kaizen Thinking",
  description: [
    "We’re strict adherents to the idea of Kaizen – making small yet continuous improvements to boost overall productivity and effectiveness. And that’s what we’ll do when we work alongside you – ensure your business is improved every day. After all, just a 1% improvement is gigantic when achieved every day for a month.",
  ],
};
interface FeatureState {
  feature1: boolean;
  feature2: boolean;
  feature3: boolean;
}
const tempFeature: FeatureState = {
  feature1: false,
  feature2: false,
  feature3: false,
};

const MobileViewThreeFeatureSection = () => {
  const [feature, setFeature] = useState<FeatureState>({
    ...tempFeature,
    feature1: true,
  });
  const handleNext = () => {
    const currentFeature = Object.keys(feature).findIndex(
      (key) => feature[key as keyof FeatureState]
    );
    const nextFeature = (currentFeature + 1) % Object.keys(feature).length;
    const newFeature = {
      ...tempFeature,
      [Object.keys(feature)[nextFeature]]: true,
    };
    setFeature(newFeature);
  };

  const handlePrev = () => {
    const currentFeature = Object.keys(feature).findIndex(
      (key) => feature[key as keyof FeatureState]
    );
    const prevFeature =
      (currentFeature - 1 + Object.keys(feature).length) %
      Object.keys(feature).length;
    const newFeature = {
      ...tempFeature,
      [Object.keys(feature)[prevFeature]]: true,
    };
    setFeature(newFeature);
  };
  return (
    <div className="mobileViewthree">
       <header className="threeFeature-heading">
          Experience DePronto’s Impact{" "}
        </header>
      <div className="mob-threeFeature-heading">
        {" "}
        {feature.feature1
          ? threeFeatureData1.heading
          : feature.feature2
          ? threeFeatureData2.heading
          : threeFeatureData3.heading}
      </div>
      <section className="mob-threeFeature-content">
        <button className="mob-threeFeature-prev" onClick={handlePrev}>
          <img src={prevIcon} alt="" />
        </button>

        <img
          src={
            feature.feature1
              ? threeFeatureData1.imagepath
              : feature.feature2
              ? threeFeatureData2.imagepath
              : threeFeatureData3.imagepath
          }
          alt="Feature"
          className="mob-threeFeature-img"
        />
        <button className="mob-threeFeature-next" onClick={handleNext}>
          <img src={nextIcon} alt="" />{" "}
        </button>
      </section>
      <section className="mob-threeFeature-text">
        {feature.feature1
          ? threeFeatureData1.description
          : feature.feature2
          ? threeFeatureData2.description
          : threeFeatureData3.description}{" "}
      </section>
    </div>
  );
};

export default MobileViewThreeFeatureSection;
