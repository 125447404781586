import "./CarouselCard.css";

interface CardProps {
  CardHeading: string;
  CardContain: string;
}
function CarouselCard({ CardContain, CardHeading }: CardProps) {
  return (
    <div style={{ display: "flex" }}>
      <div className="card-main-container">
        <div className="card-heading">{CardHeading}</div>
        <div className="card-contain-container">
          <div className="card-contain">
            <p className="role_descitption">{CardContain}</p>
          </div>
        </div>
        {/* <div className="card-contain-container">
        <div className="card-contain">
          <p className="role_descitption">{CardContain}</p>
        </div>
      </div> */}
      </div>
      {/* <div className="tenpxspace">a</div> */}
    </div>
  );
}

export default CarouselCard;
