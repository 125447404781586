import React from "react";
import Flipcard from "../Common/FlipCard/Flipcard";
 import "./WhyUs.css";

const cardData = [
  {
    frontHeaderData: "Industry Leading Expertise",
    backConrainData:
      "Our team is comprised of leaders in their respective fields, with each member exhibiting exceptional expertise in their chosen specialty. This industry-leading team includes experts in all aspects of software development – including Java, SQL, and React – allowing you to plug gaps in your team quickly with real tech development professionals.",
  },
  {
    frontHeaderData: "Low Cost, High Quality",
    backConrainData:
      "Our software development team members are some of the best in the business, as is EvoNext, our highly regarded governance, risk and compliance software, seen by many as one of the top GRC platforms around. But despite offering some of the best quality service, we also keep prices affordable, ensuring you get the best of both worlds.",
  },
  {
    frontHeaderData: "Reputation",
    backConrainData:
      "With nearly 20 years in the tech development and governance, risk & compliance software industries, we’ve got the experience and skills required to lead in software development, governance, risk & compliance, and more. And when you have a reputation as great as ours, you do everything to keep it, offering the kind of expertise other companies can only dream about.",
  },
  {
    frontHeaderData: "Drive to Succeed ",
    backConrainData:
      "It’s a tried and tested cliché, but it’s true: your success really is our success. That’s why we work incredibly hard to ensure you exceed your software development goals, instilling a desire in our team members to not just slot into your tech team, but to add something new and dynamic to the mix.",
  },
];

function WhyUs() {
  return (
    <div className="whyus-parent-container">
    <h1 className="text-center p-0 m-0 choose-Us-heading">Why Choose Us</h1>
    <div className="why-us-container">
      {cardData.map((ele, index) => {
        return (
          <>
            <Flipcard
              key={index}
              frontHeader={ele.frontHeaderData}
              backContain={ele.backConrainData}
            />
          </>
        );
      })}
    </div>
  </div>
  );
}

export default WhyUs;
